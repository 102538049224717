import React from 'react'
import ResourcesHero from './ResourcesHero'
import ResourcesWorkbook from './ResourcesWorkbook'

const Resources = () => {
  return (
    
    <><ResourcesHero /><ResourcesWorkbook /></>
  )
}

export default Resources
