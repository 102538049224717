import React, { useState } from "react";
import PodcastImage from "../../images/YoutubeImages/PoscastImage.webp";

const podcastData = [
  {
    episode: 1,
    author: "Heinrich Edimo | What the Wealthy Know About Investing That You Don’t",
    date: "15/03/2024",
    image: PodcastImage,
    link: "https://open.spotify.com/episode/5iDARX7itLiZ6SwRyJHUFm",
  },
];

const PodcastVideos = () => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  console.log(handleShowMore)
  return (
    <div className="max-w-7xl mx-auto p-3 py-7 md:p-20">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {podcastData
          .slice(0, showMore ? podcastData.length : 3)
          .map((podcast, index) => (
            <a
              key={index}
              href={podcast.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative bg-[#3C3CC8] text-white rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:bg-blue-800 transition duration-300 ease-in-out"
            >
              <div className="absolute top-2 left-2 bg-yellow-500 text-black px-1 py-1 rounded-full text-xs font-bold z-10">
                Episode: {podcast.episode}
              </div>
              <img
                src={podcast.image}
                alt={`Episode ${podcast.episode}`}
                className="w-full"
              />
              <div className="p-4">
                <h2 className="font-semibold mt-2 text-sm md:text-md">
                  {podcast.title}
                </h2>
                <p className="mt-2 text-sm">{podcast.author}</p>
                <p className="mt-2 text-sm">{podcast.date}</p>
              </div>
            </a>
          ))}
      </div>
      <div className="flex justify-center mt-10">
        {/* <button
          onClick={handleShowMore}
          className="bg-[#181349] text-white px-8 py-3 rounded-md  hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          {showMore ? "Show Less" : "Show More"}
        </button> */}
      </div>
    </div>
  );
};

export default PodcastVideos;
